export const SUPPORT_PHONE_NUMBER_MASK = "1 214-360-0061";
export const SUPPORT_PHONE_NUMBER = "+1214-360-0061";
export const SUPPORT_EMAIL = "Support@Ever.ag";
export const SUPPORT_URL = "https://everag.force.com/s/";
export const SUPPORT_URL_MASK = "EverAg.force.com/s";
export const TOP_BAR_HEIGHT = 20;
export const TOP_BAR_HEIGHT_TAILWIND = "h-20";
export const TOP_BAR_PADDING_X = 4;
export const TOP_BAR_PADDING_X_TAILWIND = "px-4";
export const PROFILE_DROPDOWN_SHIFT_TAILWIND = "-right-4";
export const DEFAULT_SPACING = 8;

export const BASE_API_URL = process.env.REACT_APP_API_BASE_URI ?? "";
export const CORS_HEADERS = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "text/plain;charset=UTF-8",
  "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  "Access-Control-Max-Age": "86400",
};
