import { ApplicationInfo } from "../types/application";
import { BASE_API_URL, CORS_HEADERS } from "../utils/constants";

export const getUserApplicationsService = async (
  accessToken: string,
  auth0Id: string
): Promise<ApplicationInfo[] | string | undefined> => {
  //must use the encodeURI function to handle special characters in the id
  const url = encodeURI(`${BASE_API_URL}/auth0/user/${auth0Id}`);

  try {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        ...CORS_HEADERS,
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse;
    }
  } catch (error: any) {
    console.log({ error });
    return "Error";
  }
};
