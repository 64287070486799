export interface CardHeaderTextProps {
  text: string;
}

const CardHeaderText = (props: CardHeaderTextProps) => {
  return (
    <p className="text-2xl text-primaryBlue font-semibold">{props.text}</p>
  );
};

export default CardHeaderText;
