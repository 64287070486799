import { useAuth0 } from "@auth0/auth0-react";
import HeaderSpacer from "../components/spacers/headerSpacer";
import PageHeaderText from "../components/text/pageHeaderText";
import ApplicationsSection from "../components/WelcomePage/applicationsSection";
import SupportSection from "../components/WelcomePage/supportSection";

const WelcomePage = () => {
  const { user } = useAuth0();

  return (
    <div className="w-full h-full flex flex-col px-8 py-4 items-start">
      {/* If there is a user name display that, otherwise display Welcome back! */}
      <PageHeaderText
        text={`Welcome back${
          user != null && user?.name != null ? `, ${user.name}` : "!"
        }`}
      />
      <HeaderSpacer />
      <div className="w-full h-full flex flex-col lg:md:px-8 lg:md:py-4 justify-center space-y-4 lg:md:flex-row lg:md:space-x-14 lg:md:space-y-0">
        <ApplicationsSection />
        <SupportSection />
      </div>
      <HeaderSpacer />
    </div>
  );
};

export default WelcomePage;
