import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/HorizontalLogoBlueTeal.svg";
import {
  TOP_BAR_HEIGHT_TAILWIND,
  TOP_BAR_PADDING_X_TAILWIND,
} from "../../utils/constants";
import TopProfile from "./topProfile";

const TopBar = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth0();

  return (
    // Tailwind cannot handle partial class names so make sure to always use a full class name in a template literal
    // ref: https://tailwindcss.com/docs/content-configuration#dynamic-class-names
    <div
      className={`w-full ${TOP_BAR_HEIGHT_TAILWIND} flex flex-row ${TOP_BAR_PADDING_X_TAILWIND} justify-between items-center`}
    >
      <img
        src={logo}
        className={`${TOP_BAR_HEIGHT_TAILWIND} cursor-pointer`}
        alt="logo"
        onClick={() => navigate("/")}
      />
      {isAuthenticated && user != null && <TopProfile />}
    </div>
  );
};

export default TopBar;
