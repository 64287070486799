import { Auth0Provider } from "@auth0/auth0-react";
import NavWrapper from "./components/Navigation/navWrapper";

function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ""}
      //We'll want to update this if the app gets any more routes than just the current one
      redirectUri={window.location.origin}
      scope="read:userapplications"
      audience="https://my.ever.ag"
    >
      <NavWrapper />
    </Auth0Provider>
  );
}

export default App;
