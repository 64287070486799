import { ReactComponent as Phone } from "../assets/icons/Phone.svg";
import { ReactComponent as Mail } from "../assets/icons/Mail.svg";
import { ReactComponent as Link } from "../assets/icons/Link.svg";
import { ReactComponent as Cancelled } from "../assets/icons/Cancelled.svg";
import { ReactComponent as CaretRight } from "../assets/icons/CaretRight.svg";
import { ReactComponent as CaretLeft } from "../assets/icons/CaretLeft.svg";
import { ReactComponent as CaretUp } from "../assets/icons/CaretUp.svg";
import { ReactComponent as CaretDown } from "../assets/icons/CaretDown.svg";
import { ReactComponent as CloseX } from "../assets/icons/CloseX.svg";
import { ReactComponent as User } from "../assets/icons/User.svg";
import { ReactComponent as UserDuo } from "../assets/icons/UserDuo.svg";
import { ReactComponent as SignIn } from "../assets/icons/SignIn.svg";
import { ReactComponent as SignOut } from "../assets/icons/SignOut.svg";

// Follow the pattern here for adding and using more icons
// 1. Store icon svg in src/assets/icons/
// 2. import it here as shown above
// 3. Add it to the icon names enum
// 4. add it as a case in the iconSelector function

export enum IconNames {
  "Phone",
  "Mail",
  "Link",
  "Cancelled",
  "CaretRight",
  "CaretLeft",
  "CaretUp",
  "CaretDown",
  "CloseX",
  "User",
  "UserDuo",
  "SignIn",
  "SignOut",
}

export const iconSelector = (iconName: IconNames) => {
  switch (iconName) {
    case IconNames.Phone:
      return Phone;
    case IconNames.Mail:
      return Mail;
    case IconNames.Link:
      return Link;
    case IconNames.Cancelled:
      return Cancelled;
    case IconNames.CaretRight:
      return CaretRight;
    case IconNames.CaretLeft:
      return CaretLeft;
    case IconNames.CaretUp:
      return CaretUp;
    case IconNames.CaretDown:
      return CaretDown;
    case IconNames.CloseX:
      return CloseX;
    case IconNames.User:
      return User;
    case IconNames.UserDuo:
      return UserDuo;
    case IconNames.SignIn:
      return SignIn;
    case IconNames.SignOut:
      return SignOut;
    default:
      return Cancelled;
  }
};
