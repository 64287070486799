import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import colors from "../../styles/colors";
import { PROFILE_DROPDOWN_SHIFT_TAILWIND } from "../../utils/constants";
import { IconNames, iconSelector } from "../../utils/icons";
import TextSpacer from "../spacers/textSpacer";
import StandardText from "../text/standardText";

const TopProfile = () => {
  const DefaultProfileIcon = iconSelector(IconNames.UserDuo);
  const CaretDownIcon = iconSelector(IconNames.CaretDown);
  const CaretUpIcon = iconSelector(IconNames.CaretUp);
  const SignOutIcon = iconSelector(IconNames.SignOut);

  const [expanded, setExpanded] = useState(false);
  const { logout, user } = useAuth0();

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      onClick={handleClick}
      className="flex flex-row w-inherit h-full items-center cursor-pointer"
    >
      <div className="h-10 w-px flex bg-neutralGray" />
      <div className="flex flex-col w-inherit h-full relative">
        <div className="flex w-inherit h-full pl-3 items-center space-x-2 ">
          <DefaultProfileIcon
            width={24}
            height={24}
            fill={colors.primaryBlue}
          />
          {/* Code to use the profie image from auth0 for use in the future if we need it */}
          {/* {user != null && user?.picture && (
            <img src={user.picture} alt="profile picture" className="w-6 h-6" />
          )} */}
          {user != null && user?.email != null && (
            <StandardText text={user?.name || user.email} />
          )}
          {!expanded && <CaretDownIcon width={16} height={16} />}
          {expanded && <CaretUpIcon width={16} height={16} />}
        </div>
        {expanded && (
          // the profile dropdown shift adjusts the dropdown box to align flush with the right side of the screen
          <div
            className={`w-inherit h-inherit flex flex-col absolute top-full ${PROFILE_DROPDOWN_SHIFT_TAILWIND} z-10 items-start justify-start rounded-md bg-white shadow-cardShadow`}
          >
            {/* Only render this gray background box if we have the user's name, email, or both */}
            {user != null && (user?.name != null || user?.email != null) && (
              <div className="w-full h-inherit flex flex-col px-10 py-4 bg-backgroundGray cursor-auto">
                {user?.name != null && (
                  <p className="text-base text-darkNeutralGray font-normal">
                    {user.name}
                  </p>
                )}
                {user?.name != null && user?.email != null && <TextSpacer />}
                {user?.email != null && (
                  <p className="text-base text-darkNeutralGray font-normal">
                    {user.email}
                  </p>
                )}
              </div>
            )}
            {/* We could set this return uri more dynamically if the future if we have a specific route we want to redirect them to but since it's only one page this works for now */}
            <div
              onClick={() => logout({ returnTo: window.location.href })}
              className="w-full h-inherit flex flex-row px-2.5 py-3 cursor-pointer"
            >
              <SignOutIcon
                width={24}
                height={24}
                fill={colors.text}
                className="mr-3"
              />
              <StandardText text="Sign Out" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopProfile;
