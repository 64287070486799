export interface SmallDescriptionTextProps {
  text: string;
}

const SmallDescriptionText = (props: SmallDescriptionTextProps) => {
  return (
    <p className="text-xs text-placeholderGray font-normal text-start">
      {props.text}
    </p>
  );
};

export default SmallDescriptionText;
