import React from "react";

const Card = (props: { children: React.ReactNode }) => {
  return (
    <div className="w-inherit h-inherit flex flex-col px-4 py-6 md:lg:pl-8 md:lg:pr-32 md:lg:py-6 items-start justify-start rounded-lg bg-white shadow-cardShadow">
      {props.children}
    </div>
  );
};

export default Card;
