import PageHeaderText from "../components/text/pageHeaderText";

const ErrorPage = () => {
  return (
    <div className="w-full h-full flex p-8 items-center justify-center">
      <PageHeaderText text="404 Page Not Found" />
    </div>
  );
};

export default ErrorPage;
