import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { GridLoader } from "react-spinners";
import { getUserApplicationsService } from "../../services/cloudflareServices";
import colors from "../../styles/colors";
import { ApplicationInfo } from "../../types/application";
import HeaderSpacer from "../spacers/headerSpacer";
import HeaderText from "../text/headerText";
import StandardText from "../text/standardText";
import ApplicationLinkCard from "./applicationLinkCard";

const ApplicationsSection = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [applications, setApplications] = useState<ApplicationInfo[]>([]);
  const [applicationsIsLoading, setApplicationsIsLoading] = useState(true);

  const getUserApps = async () => {
    //check if user id (user.sub) is valid before making api call
    if (user != null && user?.sub != null) {
      setApplicationsIsLoading(true);
      const accessToken = await getAccessTokenSilently();
      const getUserAppsResponse = await getUserApplicationsService(
        accessToken,
        user.sub
      );

      //the response will be a string if it's an error, so we check to see if it's null, undefinded, or a string
      if (
        getUserAppsResponse != null &&
        typeof getUserAppsResponse !== "string"
      ) {
        setApplications(getUserAppsResponse);
      } else {
        setApplications([]);
      }

      setApplicationsIsLoading(false);
    }
  };

  useEffect(() => {
    getUserApps();
  }, []);

  return (
    <div className="h-inherit flex flex-col items-start">
      <HeaderText text="Your Applications" />
      <HeaderSpacer />
      {/* Show loading indicator here while api call is running */}
      {applicationsIsLoading && (
        <div className="h-inherit md:lg:w-96 md:lg:p-20 flex justify-center items-center">
          <GridLoader
            color={colors.activeTeal}
            loading
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      {!applicationsIsLoading && (
        <>
          {/* Only render the applications list if there is at least one */}
          {applications.length >= 1 &&
            applications.map((application, index) => (
              <ApplicationLinkCard
                key={`${application.name}-${application.description}-${index}`}
                {...application}
              />
            ))}
          {/* Display an error message otherwise */}
          {applications.length < 1 && (
            <StandardText text="We're sorry, we couldn't find any applications associated with your user information." />
          )}
        </>
      )}
    </div>
  );
};

export default ApplicationsSection;
