export interface ColorTypes {
  primaryBlue: string;
  secondaryBlue: string;
  text: string;
  placeholderGray: string;
  secondaryGray: string;
  neutralGray: string;
  darkNeutralGray: string;
  backgroundGray: string;
  activeTeal: string;
  success: string;
  error: string;
  warning: string;
}

const colors: ColorTypes = {
  primaryBlue: "#274856",
  secondaryBlue: "#00587D",
  text: "#1F2223",
  placeholderGray: "#6E6F70",
  secondaryGray: "#8C8D8E",
  neutralGray: "#E3E3E3",
  darkNeutralGray: "#515557",
  backgroundGray: "#F7F7F7",
  activeTeal: "#337D6B",
  success: "#32A675",
  error: "#CC3D33",
  warning: "#F2C318",
};

export default colors;
