export interface StandardLinkTextProps {
  text: string;
}

const StandardLinkText = (props: StandardLinkTextProps) => {
  return (
    <p className="text-base text-secondaryBlue font-normal hover:underline">
      {props.text}
    </p>
  );
};

export default StandardLinkText;
