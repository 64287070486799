import { IconNames, iconSelector } from "../../utils/icons";
import StandardLinkText from "../text/standardLinkText";

export interface LinkWithIconProps {
  link: string;
  linkText: string;
  linkType: "email" | "website" | "phone";
  iconName: IconNames;
}

const LinkWithIcon = (props: LinkWithIconProps) => {
  const Icon = iconSelector(props.iconName);

  //format the link based on the type to append the proper prefixes
  const getHref = (): string => {
    switch (props.linkType) {
      case "website":
        return props.link;
      case "email":
        return `mailto:${props.link}`;
      case "phone":
        return `tel:${props.link}`;
      default:
        return props.link;
    }
  };

  return (
    <a href={getHref()} target="_blank">
      <div className="flex flex-row items-center rounded-sm space-x-1 cursor-pointer">
        <Icon width={16} height={16} />
        <StandardLinkText text={props.linkText} />
      </div>
    </a>
  );
};

export default LinkWithIcon;
