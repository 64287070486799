export interface StandardDescriptionTextProps {
  text: string;
}

const StandardDescriptionText = (props: StandardDescriptionTextProps) => {
  return (
    <p className="text-base text-placeholderGray font-normal">{props.text}</p>
  );
};

export default StandardDescriptionText;
