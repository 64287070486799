import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { DotLoader } from "react-spinners";
import WelcomePage from "../../pages/welcomePage";
import colors from "../../styles/colors";
import TopBar from "./topBar";

function NavWrapper() {
  const pathname = window.location.pathname;
  const search = window.location.search;
  const { isAuthenticated, user, loginWithRedirect, isLoading, error } =
    useAuth0();

  const handleLogin = async () => {
    loginWithRedirect();
  };

  useEffect(() => {
    if (pathname === "/login" && !isAuthenticated) {
      handleLogin();
    }
  }, [isAuthenticated, pathname]);

  useEffect(() => {
    //checks the url query for a code, isAuthenticated is sometimes not updated in time after a redirect so if the code exists we know we just authenticated and don't want to redirect again
    if (!isAuthenticated && !search.includes("code=")) {
      handleLogin();
    }
  }, [search, isAuthenticated]);

  useEffect(() => {
    if (error != null) {
      console.log("Error with Auth0: ", error);
    }
  }, [error]);

  return (
    <div className="divide-y-2 divide-default/25">
      <TopBar />
      {isAuthenticated && user != null && <WelcomePage />}
      {(!isAuthenticated || user == null || isLoading) && (
        <div className="w-full h-full flex flex-col px-8 py-8 items-center">
          <DotLoader
            color={colors.activeTeal}
            loading
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </div>
  );
}

export default NavWrapper;
