import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE_NUMBER,
  SUPPORT_PHONE_NUMBER_MASK,
  SUPPORT_URL,
  SUPPORT_URL_MASK,
} from "../../utils/constants";
import { IconNames } from "../../utils/icons";
import Card from "../common/card";
import LinkWithIcon from "../common/linkWithIcon";
import HeaderSpacer from "../spacers/headerSpacer";
import StandardSpacer from "../spacers/standardSpacer";
import TextSpacer from "../spacers/textSpacer";
import CardHeaderText from "../text/cardHeaderText";
import HeaderText from "../text/headerText";
import SectionHeaderText from "../text/sectionHeaderText";
import SmallDescriptionText from "../text/smallDescriptionText";

const SupportSection = () => {
  return (
    <div className="w-inherit h-inherit flex flex-col items-start">
      <HeaderText text="Support" />
      <HeaderSpacer />
      <Card>
        <CardHeaderText text="Need help?" />
        <StandardSpacer />

        <SectionHeaderText text="SUPPORT LINE:" />
        <TextSpacer />
        <LinkWithIcon
          linkText={SUPPORT_PHONE_NUMBER_MASK}
          link={SUPPORT_PHONE_NUMBER}
          linkType="phone"
          iconName={IconNames.Phone}
        />
        <TextSpacer />

        <SmallDescriptionText text="Hours: Monday - Friday from 7am - 7 pm CST" />
        <TextSpacer />
        <SmallDescriptionText text="Standard hours exclude U.S. bank and financial holidays" />
        <StandardSpacer />

        <SectionHeaderText text="SUPPORT REQUESTS:" />
        <TextSpacer />
        <LinkWithIcon
          linkText={SUPPORT_EMAIL}
          link={SUPPORT_EMAIL}
          linkType="email"
          iconName={IconNames.Mail}
        />
        <TextSpacer />
        <LinkWithIcon
          linkText={SUPPORT_URL_MASK}
          link={SUPPORT_URL}
          linkType="website"
          iconName={IconNames.Link}
        />
        <TextSpacer />

        <SmallDescriptionText text="Support requests can be submitted anytime" />
      </Card>
    </div>
  );
};

export default SupportSection;
